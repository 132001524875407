.App {
  text-align: center;
  background-color: #5697ff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  font-size: 10pt;
  color: rgb(0, 0, 0);

}
